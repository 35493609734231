import React from "react";
import PropTypes from "prop-types";
import "./HomePage.scss";

const HomePage = props => {
  return (
    <>
      <div className="home-page">
        <div className="landing-feature_container"></div>
        <h1>Testing</h1>
        Under development. <br />
        Please see <a href="https://www.shaneschmaltz.com">https://www.shaneschmaltz.com</a> at this
        time
      </div>
    </>
  );
};

HomePage.propTypes = {};

export default HomePage;
